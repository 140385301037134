import React, { useEffect, useState, useRef } from "react";
import LoginModal from "../LoginModal";
import axios from "axios";
import IconChart from './assets/icon/icon-chart.png'
import HeroVideo from "../Video/HeroVideoOct12.mp4";
import ImageScribbleHero from './assets/image/image-scribble-hero.png'
import pcmockup from '../../assets/images/product demos/desktop/heroimage.svg'

export default function Hero(props) {
  const [show, setShow] = useState(false);
  const [showDisplayFeatureModel, setDisplayFeatureModel] = useState(false);
  const [logintype, setLoginType] = useState(0);
  const [userQuestions, setUserQuestions] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [pricingPlansPopup, setPricingPlansPopup] = useState(false);
  const videoRef = useRef(null);

  const todayDate = new Date();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    props.setFlag(true);
    window.fbq("track", "CompleteRegistration");
  };

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            props.setHeroLoader(false);
            props.setIsPaymentDone(true);
            props.setShowSub(true);
            localStorage.setItem("payment", true);
            props.setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                props.setHeroLoader(false);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                props.setShowSub(true);
                props.setShowInst(true);
              } else {
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            props.setHeroLoader(false);
            props.setPaymentPopup(true);
            props.setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            localStorage.removeItem("payment");
          }
        });
    } catch (err) {
      if (err.request) {
        if (err.request.status == 401) {
          props.setHeroLoader(false);
          props.setLoggedInId("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  return (
    <div>
      <section className="w-full pt-12 grid grid-cols-12 max-md:grid-cols-4 px-[100px] max-md:px-[20px] items-center">
        <div className="col-span-10 max-md:col-span-4 max-md:col-start-1 col-start-2 p-10 pb-20 flex flex-col items-center justify-center gap-10 rounded-[20px] border border-[rgba(169, 176, 193, 0.60)] border-dashed bg-black bg-opacity-[0.01]">

          <div className="flex flex-col gap-7 justify-center items-center">
            <div className="w-fit flex py-1 px-2 items-center justify-center gap-[6px] rounded-[40px] border-[0.5px] border-purple bg-purple bg-opacity-5">
              <img src={IconChart} width={16} height={16} alt="icon-chart"/>
              <div className="font-manrope leading-[130%] tracking-[-0.32px] text-b2 font-normal text-grey-90">
                Used by 
                <span className="text-purple">
                  &nbsp;600K Students
                </span>
              </div>
            </div>
            <span className="font-product font-normal text-[76px] max-md:text-[50px] text-grey-90 text-center leading-[114%] max-md:leading-[110%] tracking-[-2.28px] max-md:tracking-[-1.2px]">
              Answers to all your
              <span className="hidden max-md:inline">&nbsp;</span>
              <span className="inline max-md:hidden"><br/></span>
              questions in&nbsp;
              <span className="relative">
                seconds.
                <img src={ImageScribbleHero} alt="image-scribble-hero" className="absolute left-0 top-0 w-[312.169px] h-auto z-20"/>
              </span>
            </span>
            <span className="font-sfpro font-light text-[20px] text-[#4f4f57] text-center leading-[130%] tracking-[-0.6px]">
              Snap a photo, get an answer, and ask follow-up
              <span className="hidden max-md:inline">&nbsp;</span>
              <span className="inline max-md:hidden"><br/></span>
              questions. Get solutions to your problems instantly.
            </span>
          </div>
          <button 
          className="hover:opacity-90 hover:scale-[0.98] transition-all bg-gradient-to-b from-[#564CDA] to-[#2E16CB] p-[2px] w-fit rounded-[12px] shadow-[0_4px_17px_0px_rgba(70, 58, 209, 0.3)]"
          onClick={handleShow}
          >
              <div className="p-2 px-8 rounded-[10px] border bg-gradient-to-b from-gradient-secondary-start to-gradient-secondary-end shadow-[inset_0_4px_5px_0px_rgba(255,255,255,0.4)]">
                  <p 
                    className="font-lexend font-regular tracking-normal" 
                    style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                  >
                    Start Asking 
                  </p>
              </div>
          </button>
        </div>
        
        <div
          className="col-span-12"
          style={{ marginTop: '-60px' }}
        >
          {/* <img className='w-full' src={pcmockup} alt="image-mockup-hero"/> */}
          <video
                  ref={videoRef}
                  className="object-fill w-full self-start mx-auto rounded-2xl"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={HeroVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </div>
      </section>

      <LoginModal
        setHeroLoader={props.setHeroLoader}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        setFlag={props.setFlag}
        flag={props.flag}
        setEmailData={setEmailData}
        emailData={emailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        logintype={logintype}
        setLoginType={setLoginType}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={props.setLoggedInId}
        loggedInId={props.loggedInId}
        setIsPaymentDone={props.setIsPaymentDone}
        setPaymentPopup={props.setPaymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
    </div>
  );
}
