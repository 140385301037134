import React, { useState, useRef, useEffect } from "react";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import toast from "react-hot-toast";
import GoogleApplePay from "../GoogleApplePay";
import daimond from "../images/daimond.png";

export default function PaymentForm(props) {
  const paymentLoader = useRef();
  const [billingDetails, setBillingDetails] = useState({
    name: "",
  });
  const [nameError, setNameError] = useState(false);
  const [carNumError, setCardNumError] = useState(false);
  const [expDateError, setExpDateError] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [intentId, setIntentId] = useState("");
  const [stripeCoupon, setStripeCoupon] = useState("");
  const [showPromoCodeText, setShowPromoCodeText] = useState(false);
  const [showTailSpin, setShowTailSpin] = useState(false);
  const [showstripeCouponErr, setShowStripeCouponErr] = useState(false);
  const [stripeCouponErr, setStripeCouponErr] = useState("");
  const [stripePromoTag, setStripePromoTag] = useState(false);
  const [stripePromoTagName, setStripePromoTagName] = useState("");
  const [stripeCouponID, setStripeCouponID] = useState("");
  const [showTermsComponent, setShowTermsComponent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [cardName, setCardName] = useState(false);
  const [cardNumber, setCardNumber] = useState(false);
  const [cardExp, setCardExp] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [addressError, setAddressError] = useState(false);
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [amountOff, setAmountOff] = useState(null);

  const DARK_CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#6D28D9",
        color: "white",
        "::placeholder": {
          color: "rgba(255, 255, 255, 0.20)",
        },
        border: "none",
        ":-webkit-autofill": {
          color: "white",
        },
      },
    },
  };

  useEffect(() => {
    let checkNewUser = localStorage.getItem("payment");
    setShowTermsComponent(
      checkNewUser != null && checkNewUser != undefined && checkNewUser != ""
        ? false
        : true
    );
  }, []);

  const imageChange = (e) => {
    if (e.empty === false) {
      setCardNumError(false);
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "eur",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
      if (paymentRequest) {
        paymentRequest.on("paymentmethod", async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              "pk_test_51LKtQyEiAAQw6fIgPF2Y7JmNU4xO408KgyamKvacZKwg1o2RYCqTx85LkccC2ZJE2AKJx6C5UUvySH2KRBi0UMWv006pN2pDG6",
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete("fail");
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete("success");
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                "pk_test_51LKtQyEiAAQw6fIgPF2Y7JmNU4xO408KgyamKvacZKwg1o2RYCqTx85LkccC2ZJE2AKJx6C5UUvySH2KRBi0UMWv006pN2pDG6"
              );
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
              }
            } else {
              // The payment has succeeded.
            }
          }
        });
      }
    }
  }, [stripe]);

  function on3DSComplete() {
    // Hide the 3DS UI

    // Check the PaymentIntent
    stripe.retrievePaymentIntent(intentId).then(function (result) {
      if (result.error) {
        // PaymentIntent client secret was invalid
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // Show your customer that the payment has succeeded
        } else if (result.paymentIntent.status === "requires_payment_method") {
          // Authentication failed, prompt the customer to enter another payment method
        }
      }
    });
  }

  window.addEventListener(
    "message",
    function (ev) {
      if (ev.data === "3DS-authentication-complete") {
        on3DSComplete();
      }
    },
    false
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (billingDetails.name === "") {
      setNameError(true);
    }

    if (billingAddress == "") {
      setAddressError(true);
    }

    paymentLoader.current.style.display = "flex";

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: billingDetails,
    });

    if (error) {
      if (error.code === "incomplete_number") {
        setCardNumError(true);
      }
      if (error.code === "incomplete_expiry") {
        setExpDateError(true);
      }
      if (error.code === "incomplete_cvc") {
        setCvcError(true);
      }
    }

    if (nameError || carNumError || expDateError || cvcError) {
      paymentLoader.current.style.display = "none";
    }
    if (!error) {
      const { id } = paymentMethod;
      analiticalCall();
      completePaymentProcess(id);
      analticCall();
    } else {
      paymentLoader.current.style.display = "none";
      toast.error(error.message);
    }
  };

  const completePaymentProcess = async (paymentMethodId) => {
    try {
      const mobile_number = props.mobile_number
        ? props.mobile_number
        : JSON.parse(localStorage.getItem("number"));
      const urlAddCard = `${process.env.REACT_APP_API_ENDPOINT}/make-payment`;
      const response = await axios.post(
        urlAddCard,
        {
          name: billingDetails.name,
          id: paymentMethodId,
          mobile_number: mobile_number,
          coupon: stripeCouponID ? stripeCouponID : undefined,
          couponCode: stripeCoupon.trim() !== "" ? stripeCoupon : undefined,
          subscription_plan: props.subscriptionPlan,
          address_line1: billingAddress,
          address_line2: addressLine2,
          country: country,
          city: city,
          zipCode: zipCode,
          countryCode: localStorage.getItem("countryCode"),
        },

        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (response.data.status === true) {
        if (response.data.payment_status == "succeeded") {
          paymentLoader.current.style.display = "none";
          setSuccess(true);
          if (response.data.msg === "Subscription Added") {
            localStorage.setItem("payment", true);
            props.setIsPaymentDone(true);
          }
          setTimeout(() => {
            document.location.reload();
          }, 3000);
        } else if (response.data.payment_status === "requires_action") {
          const result = await stripe.confirmCardPayment(
            response.data.client_secret,
            {
              payment_method: paymentMethodId,
              return_url: response.data.return_url,
            }
          );
          if (result.error) {
            paymentLoader.current.style.display = "none";
            toast.error(result.error.message);
          } else {
            if (result.paymentIntent.status === "succeeded") {
              const apiResponse = await axios.get(
                `${response.data.return_url}&payment_intent=${result.paymentIntent.id}`
              );
              paymentLoader.current.style.display = "none";
              if (apiResponse.data.status === true) {
                setSuccess(true);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                setTimeout(() => {
                  document.location.reload();
                }, 3000);
              } else {
                toast.error(result.data.msg);
              }
            }
          }
        }
      } else if (response.data.subscribed === true) {
        props.setIsPaymentDone(true);
        toast.success(response.data.msg);
        paymentLoader.current.style.display = "none";
        props.setPaymentPopup(false);
      } else {
        paymentLoader.current.style.display = "none";
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error", error);
      paymentLoader.current.style.display = "none";
    }
  };

  const analiticalCall = () => {
    window.ttq.track("AddPaymentInfo", { value: 14.99, currency: "USD" });
    window.ttq.track("PlaceAnOrder", { value: 14.99, currency: "USD" });
  };

  const analticCall = () => {
    window.ttq.track("CompletePayment", { value: 14.99, currency: "USD" });
    window.fbq("track", "CompletePayment", {
      value: 14.99,
      currency: "USD",
    });
    axios
      .get(
        "https://forbusiness.snapchat.com/blog/the-snap-pixel-how-it-works-and-how-to-install-it#:~:text=%2D%20We%20have%20standard%20pixel%20events,event%20you%20want%20to%20track"
      )
      .then(function (response) { })
      .catch((err) => {
        console.error(err);
      });
  };

  const checkStripeCoupon = async (promoCode) => {
    setShowTailSpin(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/valid-promo-code`,
        {
          promo_code: promoCode,
          stripeCustomerID: localStorage.getItem("stripeCustomerId"),
        },

        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (response.data.status) {
        setStripePromoTagName(response.data.couponName);
        setStripePromoTag(true);
        setShowTailSpin(false);
        setStripeCouponID(response.data.data.data[0].id);
        setIsPromoCodeValid(true);

        setAmountOff(response.data.data.data[0].coupon.amount_off / 100);
      } else {
        setShowStripeCouponErr(true);
        setStripeCouponErr(response.data.msg);
        setShowTailSpin(false);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handlePromoCodeChange = (e) => {
    setStripeCoupon(e.target.value);
    setIsPromoCodeValid(false);
    setShowStripeCouponErr(false);
  };
  const handleValidatePromoCode = () => {
    if (stripeCoupon.trim() !== '') {
      checkStripeCoupon(stripeCoupon);
    }
  };

  const handleRemoveTag = (e) => {
    setStripeCoupon("");
    setStripeCouponID("");
    setStripePromoTag(false);
    setShowPromoCodeText(false);
    setStripeCoupon("");
    setIsPromoCodeValid(false);
    setAmountOff(null);
  };

  const handleCloseModal = () => {
    props.setShowStripePaymentModel(false);
  };

  const returnBack = () => {
    props.setShowPurchasePlan(true);
    props.setShowExplainModel(false);
    props.setShowStripePaymentModel(false);
  };

  return (
    <>
      {!success ? (
        <div>
          <Modal
            onHide={handleCloseModal}
            show={props.showStripePaymentModel}
            id="paymentModal"
            className="payment-modal"
          >
            <div
              className="ring-loader"
              ref={paymentLoader}
              style={{ display: "none", placeContent: "center" }}
            >
              <ClipLoader size={250} color={"#fff"} />
            </div>

            <Modal.Header closeButton className="m-0"></Modal.Header>
            <Row style={{ display: "contents", padding: "20px" }}>
              <Col
                lg={12}
                md={12}
                sm={12}
                style={{ paddingRight: "0px !important" }}
              ></Col>
            </Row>
            <Form onSubmit={handleSubmit} className="">
              <Col lg={12} md={12} className="Card">
                <div className="google-apple-pay-btn mt-2 mb-2">
                  <GoogleApplePay
                    completePaymentProcess={completePaymentProcess}
                  />
                </div>
              </Col>

              <Col lg={12} md={12} className="px-3">
                <div onClick={returnBack} className="button_arrow_icon">
                  <span style={{ cursor: "pointer" }}>&#8592; Back</span>
                </div>
                <div className="answerAi_subscription__ p-3 mt-4">
                  <div className="d-flex align-items-center">
                    <img width={50} height={50} src={daimond} alt="daimond" />
                    <div
                      className="ps-2"
                      style={{ color: "#fff", fontSize: "15px" }}
                    >
                      AnswersAi Subscription{" "}
                      {props.subscriptionPlan === "SEMIANNUALLY" ? (
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "lighter !important",
                          }}
                        >
                          {localStorage.getItem("countryCode") == "AU"
                            ? "6.66*6 = A$39.99"
                            : "12.99*6 = $77.94"}
                        </span>
                      ) : props.subscriptionPlan === "YEARLY" ? (
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "lighter !important",
                          }}
                        >
                          {localStorage.getItem("countryCode") == "AU"
                            ? "5.83*12 = A$69.99"
                            : "9.99*12 = $119.88"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="mt-1"
                        style={{
                          color: "rgba(255, 255, 255, 0.60)",
                          fontSize: "12px",
                        }}
                      >
                        {props.subscriptionPlan === "SEMIANNUALLY"
                          ? "Every 6 months"
                          : props.subscriptionPlan === "MONTHLY"
                            ? "Every time"
                            : "Every Year"}
                        , cancel anytime{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset className="FormGroup mt-4">
                  <div className="FormRow mb-3">
                    <Form.Group className="" controlId="formBasicEmail">
                      <input
                        type="text"
                        className="mt-2"
                        placeholder="Name"
                        id="name"
                        name="name"
                        value={billingDetails.name}
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            name: e.target.value,
                          });
                          if (e.target.value !== "") {
                            setNameError(false);
                            setCardName(true);
                          } else {
                            setCardName(false);
                          }
                        }}
                      />
                    </Form.Group>
                    {nameError === true ? (
                      <p className="payment-form-error">Name is required</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="FormRow">
                    <div className="mb-3">
                      <label>
                        <CardNumberElement
                          className="text-light mycard"
                          name="card-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            setCardNumber(false);
                            if (event.complete) {
                              setCardNumber(event.complete);
                            }
                            imageChange(event);
                          }}
                        />
                        {carNumError === true ? (
                          <p className="payment-form-error">
                            Card number is empty or incomplete
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label
                        style={{ width: "100%" }}
                        className="mb-3 text-light"
                      >
                        <CardExpiryElement
                          className="expiry-date text-light"
                          name="expiry-date"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            setCardExp(false);
                            if (event.complete) {
                              setCardExp(event.complete);
                            }
                            if (event.empty === false) {
                              setExpDateError(false);
                            }
                          }}
                        />
                        {expDateError === true ? (
                          <p className="payment-form-error">
                            Expiration Date is empty or incorrect
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                      <label className="text-light" style={{ width: "100%" }}>
                        <CardCvcElement
                          className=" cvc-number text-dark"
                          placeholder="Name"
                          name="cvc-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            setCardCvc(false);
                            if (event.complete) {
                              setCardCvc(event.complete);
                            }
                            if (event.empty === false) {
                              setCvcError(false);
                            }
                          }}
                        />
                        {cvcError === true ? (
                          <p className="payment-form-error">CVC is required</p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div
                      className="text-light mb-5 mt-1"
                      style={{ width: "100%" }}
                    >
                      <input
                        placeholder="Address line 1"
                        onChange={(e) => setBillingAddress(e.target.value)}
                        type="text"
                      />
                      {addressError === true ? (
                        <p className="payment-form-error">
                          Address is required
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className="text-light mb-5 mt-1"
                      style={{ width: "100%" }}
                    >
                      <input
                        placeholder="Address line 2"
                        onChange={(e) => setAddressLine2(e.target.value)}
                        type="text"
                      />
                    </div>

                    <div className="d-md-flex">
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="country"
                          onChange={(e) => setCountry(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="city"
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="zip code"
                          onChange={(e) => setZipCode(e.target.value)}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="FormRow promo-code-section">
                    <Form.Group controlId="promoCode" className="d-flex align-items-center w-100">
                      <Form.Control
                        type="text"
                        placeholder="Enter Promo Code"
                        value={stripeCoupon}
                        onChange={handlePromoCodeChange}
                        isValid={isPromoCodeValid}
                        isInvalid={showstripeCouponErr}
                        className="text-light flex-grow-1"
                      />
                      <button
                        variant="outline-primary"
                        onClick={handleValidatePromoCode}
                        disabled={stripeCoupon.trim() === '' || showTailSpin}
                        className={`validate-btn ms-2 ${stripeCoupon.trim() === '' || showTailSpin ? 'disabled-btn' : ''}`}
                      >
                        {showTailSpin ? <ClipLoader size={20} color={"#6366f1"} /> : "Apply"}
                      </button>
                    </Form.Group>
                    {showstripeCouponErr && (
                      <Form.Control.Feedback type="invalid" className="d-block mt-1">
                        {stripeCouponErr}
                      </Form.Control.Feedback>
                    )}
                    {amountOff && (
                      <div className="text-green-500 font-bold mt-4">
                        Coupon applied successfully. ${amountOff} off your purchase.
                      </div>
                    )}
                  </div>
                  {stripePromoTag && (
                    <div className="promo-tag mt-2">
                      <span>{stripePromoTagName}</span>
                      <button onClick={handleRemoveTag} className="remove-tag">×</button>
                    </div>
                  )}
                  <div
                    className="text-center"
                    style={{
                      color: "#fff",
                      marginTop: "10px",
                      marginBottom: "6px",
                      fontSize: "17px",
                    }}
                  ></div>
                </fieldset>
                {cardNumber &&
                  cardExp &&
                  cardCvc &&
                  cardName &&
                  billingAddress.length > 0 &&
                  country.length > 0 &&
                  city.length > 0 &&
                  (stripeCoupon.trim() === '' || isPromoCodeValid) &&
                  zipCode.length > 0 ? (
                  <button
                    type="submit"
                    id="custom-button"
                    className="pay-btn mb-4"
                  >
                    Next
                  </button>
                ) : (
                  <button className="pay-btn disabled-pay-btn  mb-4" disabled>
                    Next
                  </button>
                )}{" "}
              </Col>
            </Form>
          </Modal>
        </div>
      ) : (
        <div className="" style={{ background: "#000" }}>
          <div
            style={{ background: "#fff", border: "1px solid #fff" }}
            className="p-4 text-center w-75 m-auto rounded-[20px]"
          >
            <div>
              <div className="wrapper">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </div>
            <div className="success-text">Payment Successful</div>
          </div>
        </div>
      )}
    </>
  );
}
