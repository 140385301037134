import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CookieConsent from "react-cookie-consent";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CookiePreferencesCenter from "./components/cookies/CookiePreferencesCenter";

const Root = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [cookieExists, setCookieExists] = useState(false);

  const handlePreferencesClick = () => {
    setShowPreferences(true);
  };

  useEffect(() => {
    const name = "myAppCookieConsent" + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) {
        res = val.substring(name.length);
        setCookieExists(true);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <App />

      {window.location.pathname !== '/institutions' && !cookieExists && (
        <div>
          {showPreferences ? (
            <CookiePreferencesCenter
              setCookieExists={setCookieExists}
              show={showPreferences}
              setShowPreferences={setShowPreferences}
            />
          ) : (
            <CookieConsent
              location="bottom"
              buttonText="Accept All"
              enableDeclineButton
              declineButtonText="Decline All"
              containerClasses="cookie-consent-container"
              buttonClasses="cookie-accept-button"
              declineButtonClasses="cookie-decline-button"
              contentClasses="cookie-content"
              buttonWrapperClasses="cookies-wrapper-button"
              expires={150}
              onAccept={() => {
                const preferencesString = JSON.stringify({
                  performance: true,
                  functional: true,
                  required: true,
                });
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 150);
                const formattedExpirationDate = expirationDate.toUTCString();
                document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
              }}
              onDecline={() => {
                const preferencesString = false;
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 150);
                const formattedExpirationDate = expirationDate.toUTCString();
                document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
              }}
            >
              <div className="w-full flex justify-between">
                <div className="flex flex-col gap-2 mr-2 py-2 text-white">
                  <h1 className="text-md md:text-lg font-bold">This website uses cookies.</h1>
                  <p className="leading-tight text-xs md:text-sm text-regular">Cookies help us deliver the best experience on our website. Clicking “Accept All” marks your consent to our use of cookies.
                  <a onClick={handlePreferencesClick} className="text-xs md:text-sm text-bold max-lg:underline underline-offset-4"> Manage your preferences</a>
                  </p>
                </div>
                <button
                  className="hidden lg:flex shrink-0 hover:bg-white hover:bg-opacity-10 transition-all lg:self-center Cookie_preferences_button"
                  onClick={handlePreferencesClick}
                >
                  Customize
                </button> 
              </div>
            </CookieConsent>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
reportWebVitals();
