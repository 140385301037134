import React, { useState } from "react";
import IconStar from './assets/icon/icon-star.png'
import ImageMockup from './assets/image/image-mockup-feature.png'
import IconBubble from './assets/icon/icon-bubble.png'
import IconPhone from './assets/icon/icon-phone.svg';
import IconDesktop from './assets/icon/icon-desktop.svg';
import ImageScribbleFeature from './assets/image/image-scribble-feature.png'
import pcsnap1 from '../../assets/images/product demos/desktop/snapshot1.svg'
import pcsnap2 from '../../assets/images/product demos/desktop/snapshot2.svg'
import pcsnap3 from '../../assets/images/product demos/desktop/snapshot3.svg'
import pcsnap4 from '../../assets/images/product demos/desktop/snapshot4.svg'

const FEATURES = [
    {
        title: 'Snap to solve.',
        description: 'Solve your problem with record accuracy.'
    },
    {
        title: 'Follow up questions.',
        description: 'Instantly understand any part of the solution.'
    },
    {
        title: 'Your phone sees the problem too',
        description: 'Snap it, solve it - instant solutions on your phone.'
    },
];

export default function FeatureCards() {
    const [selectedFeature, setSelectedFeature] = useState('snapshot');
    const [activeFeature, setActiveFeature] = useState(0);

    const RadioTabData = [
        { label: 'Snapshot Mode', value: 'snapshot' },
        { label: 'Lecture Mode', value: 'lecture' },
        { label: 'Talk to Book', value: 'talkToBook' }
    ]
    const FeatureData = [
        {
            id: 'snapshot',
            demo: pcsnap2,
            //TODO: This is redundant, we show the same for all modes. Refactor later.
            features: FEATURES,
        },
        {
            id: 'lecture',
            demo: pcsnap3,
            features: FEATURES,
        },
        {
            id: 'talkToBook',
            demo: pcsnap4,
            features: FEATURES,
        }
    ]
    return (
        <div className="grid grid-cols-12 max-md:grid-cols-4 px-[100px] max-md:px-[20px]">
            <div className="col-span-10 max-md:col-span-4 col-start-2 max-md:col-start-1 py-20 flex flex-col gap-20">
                <div className="flex flex-col gap-7 justify-center items-center">
                    <div className="w-fit flex py-1 px-3 items-center justify-center gap-[6px] rounded-[40px] border-[0.5px] border-purple bg-purple bg-opacity-5">
                        <img src={IconStar} width={16} height={16} alt="icon-chart"/>
                        <div className="font-manrope tracking-[-0.32px] text-b2 font-normal text-grey-90">
                            Features
                        </div>
                    </div>
                    <span className="font-product font-normal text-[56px] max-md:text-[40px] text-grey-90 text-center leading-[114%] tracking-[-1.68px] max-md:tracking-[-1.2px]">
                        Improve your grades 
                        <span className="hidden max-md:inline">&nbsp;</span>
                        <span className="inline max-md:hidden"><br/></span>
                        <span className="relative">
                            effortlessly
                            <img src={ImageScribbleFeature} alt="image-scribble-feature" className="absolute top-[100%] left-0"/>
                        </span> 
                        <span className="hidden max-md:inline"><br/></span>
                        <span className="inline max-md:hidden">&nbsp;</span>
                        with our tools.
                    </span>
                    <span className="font-product font-light text-[20px] text-[#4f4f57] text-center leading-[130%] tracking-[-0.6px]">
                        One picture or screenshot gets you instant solutions. Ask follow-up questions and
                        <span className="hidden max-md:inline">&nbsp;</span>
                        <span className="inline max-md:hidden"><br/></span>
                        explore the rest of our tools.
                    </span>
                </div>
                
                {FeatureData.filter(item => item.id === selectedFeature).map((item, index) => (
                    <img src={item.demo} alt="image-mockup-feature" className="w-full h-auto rotate-[-1.06deg] md:hidden" />
                ))}

                <div className="w-full py-10 flex flex-col items-center gap-10 max-md:gap-5 rounded-[20px] border border-[rgba(169, 176, 193, 0.60)] border-dashed bg-black bg-opacity-[0.01]">
                    <div className="flex gap-[2px] font-sfpro text-[16px] tracking-[-0.48px] leading-[130%] text-purple font-normal">
                        We're on 
                        <img src={IconPhone} alt="icon-phone" />
                        mobile
                        <span className="font-bold">and</span>
                        <img src={IconDesktop} alt="icon-phone" />
                        desktop
                    </div>
                    {/*
                    DO NOT DELETE: code for radio tab
                    <div className="flex w-[48%] p-1 h-fit rounded-[20px] bg-[#C7CCD6] bg-opacity-25 relative">
                        <div
                            className="absolute top-1 bottom-0 left-1 bg-white rounded-[20px] transition-all duration-300"
                            style={{
                                width: 'calc(100% / 3 - 8px)', 
                                height: 'calc(100% - 8px)',
                                transform: `translateX(${RadioTabData.findIndex(tab => tab.value === selectedFeature) * 105}%)`
                            }}
                        />
                        {RadioTabData.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    setActiveFeature(0);
                                    setSelectedFeature(tab.value);
                                }}
                                className="flex w-full items-center justify-center py-2 px-5 rounded-[20px] text-[#8b92a1] hover:text-purple relative z-10"
                            >
                                <span 
                                    className={`
                                        font-manrope text-[16px] font-medium leading-[130%] tracking-[-0.64px]
                                        ${selectedFeature === tab.value ? 'text-purple' : ''}
                                    `}
                                >
                                    {tab.label}
                                </span>
                            </button>
                        ))}
                    </div>
                    */}

                    {FeatureData.filter(item => item.id === selectedFeature).map((item, index) => (
                        <div
                            className="grid grid-cols-10 max-md:grid-cols-4 md:gap-10 max-md:px-7"
                            key={index}
                        >
                            <div 
                                className="col-span-8 max-md:col-span-4 col-start-2 max-md:col-start-1"
                            >
                                <img src={item.demo} alt="image-mockup-feature" className="w-full h-auto rotate-[-1.06deg] max-md:hidden" />
                            </div>
                            <div className="flex flex-col gap-8 col-span-6 max-md:col-span-4 col-start-3 max-md:col-start-1">
                            {item.features?.map((feature, index) => (
                                <div 
                                    className={`
                                        ${ activeFeature === index ? 'opcaity-100 rotate-[1.1deg]' : 'opacity-[0.15]' }
                                        w-full flex max-md:flex-col gap-6 py-4 px-7 rounded-[12px] border-[0.5px] border-[#b1bcd9] bg-[#fefefe] transition-transform duration-300
                                    `}
                                    style={{
                                        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)'
                                    }}
                                    onClick={() => {
                                        setActiveFeature(index);
                                        setSelectedFeature(RadioTabData[index].value);
                                    }}
                                >
                                    <img src={IconBubble} alt="icon-bubble" className="w-[46px] h-[46px]" />
                                    <div className='flex flex-col gap-1 font-manrope'>
                                        <span className="text-[24px] max-md:text-[22px] font-semibold leading-[130%] tracking-[-0.48px] max-md:tracking-[-0.44px] text-grey-90">
                                            {feature.title}
                                        </span>
                                        <span className="text-[18px] font-normal leading-[130%] tracking-[-0.36px] text-grey-90">
                                            {feature.description}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}