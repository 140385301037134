import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import Card from "react-bootstrap/Card";
import share from "../images/share.png";
import explanation from "../images/explantion.png";
import axios from "axios";
import { SearchBar } from "./SearchBar";
import { ShowFulllAnswer } from "./ShowFulllAnswer";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import blur from "../../components/images/Blur.png";

export const QuestionBank = ({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  setPaymentPopup,
  paymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [subject, setSubject] = useState("General");
  const [questioBankData, setQuestionBankData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFullQuestion, setShowFullQuestion] = useState(false);
  const [questionLoader, setQuestionLoader] = useState(false);
  const [shareChatModal, setShareChatModal] = useState(false);
  const [perPage, setPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [dataLength, setDataLength] = useState([]);
  const [run, setRun] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [lastQuestionId, setLastQuestionId] = useState(0);
  const [subscribeUser, setSubscribeUser] = useState(false);

  const Navigate = useNavigate();
  const id = useParams();

  useEffect(() => {
    if (!id.id) {
      setShowFullQuestion(false);
    } else {
      setShowFullQuestion(true);
    }
  }, [id]);

  useEffect(() => {
    setQuestionLoader(true);
    if (run != 1) {
      loadData();
    }
    setRun(run + 1);
  }, [currentPage]);

  const loadData = async () => {
    if (loadingMore) {
      setQuestionLoader(false);
    } else {
      setQuestionLoader(true);
    }

    setLoadingMore(true);

    try {
      let token =
        localStorage.getItem("token") != ""
          ? JSON.parse(localStorage.getItem("token"))
          : "";
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/question-bank?page=${currentPage}&limit=${perPage}&search=${searchQuery}`,
        {
          lastFetchQuestionID: lastQuestionId,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      if (response.data.status) {
        const lastId =
          response.data.data.length > 0
            ? response.data.data[response.data.data.length - 1].id
            : 0;
        setLastQuestionId(lastId);
        setSubscribeUser(response.data.subscribe_user);
        setDataLength(response.data.data);
        if (response.data.totalPage === 1) {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }

        if (
          currentPage ===
          (response.data.totalPage !== 0
            ? response.data.totalPage
            : response.data.totalPage + 1)
        ) {
          setButtonDisable(true);
        }

        if (response.data.data.length === 0) {
          setQuestionBankData((prevData) => [...prevData]);
        } else {
          setTotalPages(response.data.totalPage);
          setQuestionBankData((prevData) => [
            ...prevData,
            ...response.data.data,
          ]);
        }
      }
    } finally {
      setLoadingMore(false);
      setQuestionLoader(false);
    }
  };

  useEffect(() => {
    setQuestionBankData([]);
    setCurrentPage(1);
    const myTimeout = setTimeout(loadData, 500);
    return () => clearTimeout(myTimeout);
  }, [searchQuery]);

  const ParseAnswer = (answer) => {
    try {
      if (typeof answer !== "string" || answer.trim() === "") {
        return null;
      }

      const sanitizedAnswer = answer.replace(/[\u0000-\u001F]/g, "");
      const startIndex = sanitizedAnswer.indexOf("{");
      if (startIndex === -1 || startIndex === sanitizedAnswer.length - 1) {
        return null;
      }

      const jsonString = sanitizedAnswer.slice(startIndex);
      const answersObject = JSON.parse(jsonString);

      if (Array.isArray(answersObject.explanation)) {
        answersObject.explanation = answersObject.explanation.join("\n");
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");
        let explain = answersObject.explanation.slice(0, 120);
        if (answersObject.explanation.length > 120) {
          explain += "...";
        }

        return explain;
      } else if (typeof answersObject.explanation === "string") {
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");

        let explain = answersObject.explanation.slice(0, 120);
        if (answersObject.explanation.length > 120) {
          explain += "...";
        }

        return explain;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const optionChoice = (option) => {
    try {
      const jsonData = JSON.parse(option);

      if (jsonData.result && Array.isArray(jsonData.result)) {
        const choices = jsonData.result.map((item) => item.option_choice);
        const formattedChoices = choices.map((choice) => {
          if (typeof choice === "string" && /^\d+$/.test(choice)) {
            return choice.split("").join(", ");
          } else {
            return choice;
          }
        });
        return formattedChoices.join(", ");
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const handleShowFullAnswer = (id) => {
    Navigate(`/question-bank/${id}`);
    window.scrollTo(0, 0);
  };

  const handleShow = () => {
    setShareChatModal(true);
  };

  const handleViewMore = async () => {
    if (!subscribeUser) {
      let customer_id = localStorage.getItem("customer_id");
      if (!customer_id) {
        handleShowLogin();
      } else if (!isPaymentDone) {
        checkUserQuestions();
      } else if (isPaymentDone) {
        setShareChatModal(true);
      }
    } else {
      setLoadingMore(true);
      if (dataLength.length < perPage) {
        setButtonDisable(true);
      }
      if (!buttonDisable) {
        setCurrentPage(currentPage + 1);
      } else {
        setLoadingMore(false);
      }
    }
  };

  const todayDate = new Date();
  const checkLoggedInStatus = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          window.ttq.track("InitiateCheckout", {
            value: 14.99,
            currency: "USD",
          });
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setHeroLoader(false);
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setHeroLoader(false);
                localStorage.setItem("payment", true);
                setIsPaymentDone(true);
                setShowSub(true);
              } else {
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            setHeroLoader(false);
            localStorage.removeItem("payment");
            setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            setPaymentPopup(true);
          }
        });
    } catch (err) {
      if (err.request.status == 401) {
        setHeroLoader(false);
        setLoggedInId("");
        localStorage.removeItem("token");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("payment");
        localStorage.removeItem("number");
      }
    }

    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  const checkUserQuestions = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
        {
          user_id: localStorage.getItem("customer_id"),
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        if (response.data.userQuestion) {
          setUserQuestions(true);
        } else {
          localStorage.setItem("number", JSON.stringify(response.data.number));
          checkLoggedInStatus();
        }
      });
  };

  return (
    <React.Fragment>
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
      {!showFullQuestion ? (
        <SearchBar
          setQuestionBankData={setQuestionBankData}
          setQuestionLoader={setQuestionLoader}
          setCurrentPage={setCurrentPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loadData={loadData}
        />
      ) : (
        <ShowFulllAnswer
          setCurrentPage={setCurrentPage}
          setQuestionBankData={setQuestionBankData}
          setQuestionLoader={setQuestionLoader}
          handleClose={handleClose}
          flag={flag}
          setFlag={setFlag}
          setLoggedInId={setLoggedInId}
          setIsPaymentDone={setIsPaymentDone}
          setHeroLoader={setHeroLoader}
          setShowFullQuestion={setShowFullQuestion}
          subject={subject}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loadData={loadData}
          handleShow={handleShow}
          setShareChatModal={setShareChatModal}
          shareChatModal={shareChatModal}
          isPaymentDone={isPaymentDone}
          setShowSub={setShowSub}
          showDisplayFeatureModel={showDisplayFeatureModel}
          setDisplayFeatureModel={setDisplayFeatureModel}
          setPaymentPopup={setPaymentPopup}
          userQuestions={userQuestions}
          setUserQuestions={setUserQuestions}
          emailData={emailData}
          setEmailData={setEmailData}
          pricingPlansPopup={pricingPlansPopup}
          setPricingPlansPopup={setPricingPlansPopup}
          logintype={logintype}
          setLoginType={setLoginType}
          show={show}
          paymentPopup={paymentPopup}
          setShow={setShow}
          handleShowLogin={handleShowLogin}
        />
      )}

      <div className="container-fluid second_Question_bank_">
        <div className="container">
          {questionLoader ? (
            <div className="question_bank_loader">
              <ClipLoader color="#fff" size={100} />
            </div>
          ) : (
            <>
              <div className="second_Question_bank_buttons-content container">
                {activeTab === 1 && (
                  <div className="row justify-content-center mt-5 card_show">
                    <div className="row">
                      {questioBankData && questioBankData.length > 0 ? (
                        questioBankData?.map((item, index) => (
                          <div
                            id={`Question_bank_`}
                            key={index}
                            className="col-12 col-xl-4 col-lg-4 col-md-6 mt-md-2 mt-lg-2 d-flex justify-content-center"
                          >
                            <Card
                              onClick={() => handleShowFullAnswer(item.id)}
                              style={{
                                width: "90%",
                                backgroundColor: "black",
                                cursor: "pointer",
                                border: "1px solid rgba(238, 238, 238, 0.2)",
                                overflow: "hidden",
                              }}
                              className="mx-3 mt-3"
                            >
                              <Card.Body
                                onClick={() => setShowFullQuestion(true)}
                                className="second_question_explanation_div"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="second_Question_bank_subject">
                                    {subject}
                                  </div>
                                  <div className="share_question_card">
                                    <img src={share} alt="share" />
                                  </div>
                                </div>
                                <div className="share_question_body">
                                  {item.questions.slice(0, 120) + "..."}
                                </div>
                                <div className="footer_div_share_question">
                                  <div className="share_question_exlanation">
                                    <div className="explanation_icon d-flex align-items-center p-3 mt-4">
                                      <img src={explanation} alt="" />
                                      <span className="Explanation_text ms-2 text-light">
                                        Explanation
                                      </span>
                                    </div>

                                    {isPaymentDone || index < 3 ? (
                                      <div className="p-3 pt-1">
                                        {optionChoice(item.answers) !== null ? (
                                          optionChoice(item.answers) !== "" ? (
                                            <div className="explanation_button_ text-light">
                                              {optionChoice(item.answers)}
                                            </div>
                                          ) : (
                                            <div className="other_div"></div>
                                          )
                                        ) : null}

                                        <div className="share_question_subheading pt-2 text-light mt-4">
                                          <div>{ParseAnswer(item.answers)}</div>
                                        </div>
                                      </div>
                                    ) : (
                                      <img
                                        src={blur}
                                        alt="blur"
                                        className="w-100"
                                      />
                                    )}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <div className="col-12 text-center text-light">
                          No data found
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row justify-content-center mt-5">
                {loadingMore ? (
                  <ClipLoader color="#fff" size={40} />
                ) : questioBankData.length > 0 ? (
                  <button
                    onClick={handleViewMore}
                    style={{ display: questionLoader ? "none" : "block" }}
                    className={
                      buttonDisable
                        ? "question_view_empty_data"
                        : "question_bank_view_more"
                    }
                  >
                    View More
                  </button>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
