import React from "react";
import img1 from '../../assets/images/aboutclg1.svg';
import img2 from '../../assets/images/aboutclg2.svg';
import img3 from '../../assets/images/aboutclg3.svg';
import img4 from '../../assets/images/aboutclg4.svg';

const About = ({toggleSlideout}) => {
    return(
        <section className="flex flex-col gap-32 items-center text-center md:m-20 pb-32 font-jakarta">
            <h1 className="text-4xl lg:text-6xl font-bold leading-tight max-2-4xl">AnswersAI is at the forefront of AI in education.</h1>
            <div className="grid grid-cols-1 gap-8">
                <div className="flex gap-2 md:gap-8">
                    <div className="flex flex-col w-[60%] gap-2 md:gap-8">
                        <img src={img1} className=" pt-12 pl-12"></img>
                        <img src={img3} className="flex rotate-90 w-[30%] self-end flex lg:hidden"></img>
                        <div className="hidden lg:flex flex-col p-20 px-16 gap-2 md:gap-8 col-span-2 text-left bg-gradient-to-tr from-[#D2D2FC] to-[#EDEDFF] rounded-3xl">
                            <div className="flex flex-col gap-8">
                                <h1 className="text-3xl font-bold">Who we are</h1>
                                <p>Fed up with overpriced textbooks, inconsistent tutoring quality, and the struggle to find trustworthy resources online? We understand the challenges. That's why we created AnswersAi.
                                Harnessing the power of cutting-edge AI, AnswersAi provides immediate access to top-tier educational materials, streamlines learning, and provides support tailored to each user. 
                                Discover the future of education with AnswersAi. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[33%] gap-2 md:gap-8">
                        <img src={img2} className=""></img>
                        <img src={img3} className="w-fit hidden lg:flex"></img>
                    </div>
                </div>
                <div className="flex lg:hidden flex-col p-8 py-16 md:p-16 m-4 gap-8 col-span-2 text-left bg-gradient-to-tr from-[#D2D2FC] to-[#EDEDFF] rounded-3xl">
                    <div className="flex flex-col gap-8">
                        <h1 className="text-2xl max-md:text-center md:text-4xl font-bold">Who we are</h1>
                        <p className="max-md:text-center ">Fed up with overpriced textbooks, inconsistent tutoring quality, and the struggle to find trustworthy resources online? We understand the challenges. That's why we created AnswersAi.<br/><br/>
                                Harnessing the power of cutting-edge AI, AnswersAi provides immediate access to top-tier educational materials, streamlines learning, and provides support tailored to each user. 
                                Discover the future of education with AnswersAi. 
                                </p>
                        </div>
                </div>
            </div>
            <div className="flex flex-col text-center items-center p-12 gap-10">
              <div className="flex flex-col gap-4">
                  <h1 className="max-w-5xl text-4xl md:text-5xl font-black leading-tight">Join the AI-powered learning revolution</h1>
              </div>
              <button className="p-6 py-3 bg-accent-800/[0.2] text-[#3F2687] hover:bg-accent-800 hover:text-white w-fit backdrop-blur-3xl rounded-full border border-2 border-accent-800 drop-shadow-[0_0_24px_rgba(63,38,135,0.10)] hover:scale-[1.01] transition-all duration-300">
                <div className="flex gap-2 font-medium" onClick={toggleSlideout}>
                Get Started
                </div>
            </button>
          </div>
        </section>
    )
}

export default About;