import React, { useState } from "react";
import toggle from './assets/icon/icon-arrow-down.svg';
import uparrow from './assets/icon/icon-arrow-up.svg';
import underline from './assets/image/image-scribble-cta.svg';
import faq from './assets/image/image-scribble-faq-1.png';
import faqbg1 from './assets/image/image-scribble-faq-2.png';
import faqbg2 from './assets/image/image-scribble-faq-3.png';
import DOMPurify from "dompurify";

const FAQItem = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div>
        <div
          onClick={toggleOpen}
          className="flex py-5 justify-between border-t border-[#C9CDD7] items-center text-accent-primary font-bold"
        >
            <p>{question}</p>
            {isOpen ? <img className="rotate-180 transition-all" src={toggle}></img> : <img className="transition-all" src={toggle}></img>}
        </div>
        <div className={`${isOpen ? 'max-h-40 ' : 'max-h-0 '} overflow-hidden transition-all duration-300`}>
            <div>
            <p className="pb-8  text-left text-text-gray" dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(answer),
                    }}/>
            </div>
        </div>
      </div>
    );
  };

function Faqcta(){
    const handleMailTo = () => {
        window.location.href = 'mailto:support@answersai.com';
    };
    return(
        <section className="col-span-full p-4 flex flex-col py-48 gap-48 items-center font-manrope text-center relative tracking-tight bg-bg-light">
           <img className="hidden sm:flex absolute max-m top-24 left-0 z-10" src={faqbg1}></img>
           <img className="hidden sm:flex absolute top-96 right-0 z-10" src={faqbg2}></img>
            <div className="flex flex-col w-full items-center gap-16 z-20">
                <div className="flex items-end gap-2">
                    <h1 className="font-product font-regular text-[40px] sm:text-6xl">I have a question</h1>
                    <img className="w-8 pb-2 sm:w-fit sm:pb-1" src={faq}></img>
                </div>
                <div className="flex flex-col w-full max-w-3xl px-12 ">
                    <FAQItem question="Where can I use AnswersAi?" 
                        answer='After purchasing our subscription, you have unlimited access to our online tools through our <a href="https://answersai.com" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">website</a>, our <a href="https://chromewebstore.google.com/detail/snapshot-by-answersai/kmdgaoalcjcefbgaiookdaigjeepoldf?hl=en" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Chrome Extension</a>, and our <a href="https://onelink.to/9h62gy" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Mobile App</a> (available on iOS and Android)!'/>
                    <FAQItem question="How can I use the Chrome Extension?" 
                        answer='Download our extension at the <a href="https://chromewebstore.google.com/detail/snapshot-by-answersai/kmdgaoalcjcefbgaiookdaigjeepoldf?hl=en" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Chrome Web Store</a>. Once you have installed the extension, you can use it on any website by clicking on the AnswersAi icon in the top right corner of your browser.'/>
                    <FAQItem question="How can I download the mobile app?" 
                        answer='You can download the app <a href="https://onelink.to/9h62gy" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">here</a>! The app is available on both iOS and Android devices.'/>
                    <FAQItem question="How do I change my subscription plan or cancel my subscription?" 
                        answer='Please log on to your account on our website and go to the "Subscription" tab. From there, you can change your subscription plan or cancel your subscription. If you have any issues, please email us at support@answersai.com.'/>
                    <FAQItem question="What if I see my content being infringed on this website?" 
                        answer='Please refer to our  <a href="/copyright-policy" style="color: #1a73e8; text-decoration: underline;">Copyright Dispute Policy</a> to learn how to request a takedown.'/>
                 </div>
            </div>
            <div className="flex flex-col items-center gap-5">
                <div className="flex gap-2 p-1 px-4 rounded-full border border-[#9EA2FC] bg-accent-primary bg-opacity-5">
                    <img src={uparrow}></img>
                    <p>Used & trusted by <span className="text-accent-primary font-bold">600k Students</span></p>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col font-product font-normal text-[40px] sm:text-6xl text-center items-center">
                        <h1 className="">Still have questions?</h1>
                        <h1 className="flex flex-col w-fit items-center gap-2">
                            Contact us
                            <img className="max-w-56 sm:max-w-96" src={underline}></img>
                        </h1>
                    </div>
                    <p className="font-inter text-text-gray">Happy to answer any questions you may have!</p>
                </div>
                <button 
                className="hover:opacity-90 hover:scale-[0.98] transition-all z-30 bg-gradient-to-b from-[#564CDA] to-[#2E16CB] p-[2px] w-fit rounded-[12px] shadow-[0_4px_17px_0px_rgba(70, 58, 209, 0.3)]"
                onClick={handleMailTo}
                >
                    <div className="p-2 px-8 rounded-[10px] border  border-2 bg-gradient-to-b from-gradient-secondary-start to-gradient-secondary-end shadow-[inset_0_4px_5px_0px_rgba(255,255,255,0.4)]">
                        <p className="font-normal font-lexend" 
                        style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}} >
                            Send us a message!
                        </p>
                    </div>
                </button>
            </div>
        </section>
    );
}

export default Faqcta;