import React from "react";
import LogoBrown from './assets/logo/logo-brown.png'
import LogoCal from './assets/logo/logo-cal.png'
import LogoColumbia from './assets/logo/logo-columbia.png'
import LogoCornell from './assets/logo/logo-cornell.png'
import LogoHarvard from './assets/logo/logo-harvard.png'
import LogoUIUC from './assets/logo/logo-uiuc.png'
import LogoMichigan from './assets/logo/logo-michigan.png'
import LogoPenn from './assets/logo/logo-penn.png'
import LogoStanford from './assets/logo/logo-stanford.png'
import { useEffect, useState } from 'react';

export default function Testimonials() {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  const logos = [
    LogoBrown,
    LogoCal,
    LogoColumbia,
    LogoCornell,
    LogoHarvard,
    LogoUIUC,
    LogoMichigan,
    LogoPenn,
    LogoStanford
  ];

  const renderLogos = () => {
    return logos.map((logo, index) => (
      <img key={index} src={logo} alt={`Logo ${index + 1}`} className="h-20 w-20 max-md:h-[50px] max-md:w-[50px]" />
    ));
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); 
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="grid grid-cols-12 max-md:grid-cols-4 px-[100px] max-md:px-[20px]">
      <div className="col-span-12 pt-[120px] pb-[60px] flex flex-col gap-[60px] items-center justify-center">
        <div className="flex flex-wrap py-[3px] justify-center items-center gap-2 font-product text-[30px] max-md:text-[25px] font-normal leading-[114%] tracking-[-0.9px] max-md:tracking-[-0.75px] max-md:px-5">
          <span className="flex-shrink-0">
            AnswersAi is trusted by
          </span>
          <span 
            className="text-[40px] max-md:text-[30px] font-black leading-[95%] tracking-[-2.8px] max-md:tracking-[-2.1px] rotate-[-2.716deg]"
            style={{
              textShadow: "2px 4px 9px rgba(0, 0, 0, 0.2)",
              WebkitTextStrokeWidth: isSmallScreen ? "1.4px" : "1.8px", 
              WebkitTextStrokeColor: "#FFF",
              background: "linear-gradient(91deg, #6268FF 18.67%, #8247FF 84.99%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            600K+
          </span>
          <span className="flex-shrink-0">
            students and has answered
          </span>
          <span 
            className="text-[40px] max-md:text-[30px] font-black leading-[95%] tracking-[-2.8px] max-md:tracking-[-2.1px] rotate-[3.831deg]"
            style={{
              textShadow: "2px 4px 9px rgba(0, 0, 0, 0.2)",
              WebkitTextStrokeWidth: isSmallScreen ? "1.4px" : "1.8px", 
              WebkitTextStrokeColor: "#FFF",
              background: "linear-gradient(91deg, #6268FF 18.67%, #8247FF 84.99%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            2.5M+
          </span>
          <span className="flex-shrink-0">
            questions
          </span>
        </div>

        <div className="flex w-full justify-between max-md:gap-7 max-md:w-fit max-md:overflow-hidden relative">
          {renderLogos()}
          <div className="absolute left-0 top-0 h-full w-[400px] max-md:w-[200px] bg-gradient-to-r from-white to-transparent pointer-events-none" style={{ filter: "blur(30px)" }}></div>
          <div className="absolute right-0 top-0 h-full w-[400px] max-md:w-[200px] bg-gradient-to-l from-white to-transparent pointer-events-none" style={{ filter: "blur(30px)" }}></div>
        </div>
      </div>
    </div>
  );
}
